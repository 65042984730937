@import "variables";

.badge {
  padding: .45em 1.4em;
}

.badge-success {
  background-color: transparentize($success, 0.8);
  color: darken($success, 0%);
}

.badge-warning {
  background-color: transparentize($warning, 0.8);
  color: darken($warning, 0%);
}

.badge-danger {
  background-color: transparentize($danger, 0.8);
  color: darken($danger, 0%);
}

.badge-info {
  background-color: transparentize($info, 0.8);
  color: darken($info, 0%);
}

.badge-primary {
  background-color: transparentize($primary, 0.8);
  color: darken($primary, 0%);
}

.badge-secondary {
  background-color: transparentize($secondary, 0.8);
  color: darken($secondary, 0%);
}

.badge-purple {
  background-color: transparentize($color-purple, 0.8);
  color: darken($color-purple, 0%);
}

.badge-gray{
  background-color: transparentize($color-dark-gray, 0.8);
  color: darken($color-dark-gray, 0%);
}

.badge-dark-blue {
  background-color: transparentize(  $color-dark-blue, 0.7);
  color: $color-dark-blue;
}

.badge-darker-red{
  $badge-color: #ac0081;
  background-color: transparentize($badge-color, 0.8);
  color: darken($badge-color, 0%);
}

.color-refused {
  background-color: #f8cedb;
  color: darken(#fd346c, 0%);
}

.badge-cancellation-reason{
  background-color: transparentize(#fd7a85, 0.8);
  color: darken(#f91b3a, 0%);
}
