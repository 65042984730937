.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.modal-footer{
  border-top: 0;
  padding-top: 0;
}

.modal-right{
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  margin: 0 !important;
  width: 50%;
  height: 100%;
  background-color: white;

  .modal-content{
    height: 100%;
    overflow: auto;
    border-radius: 0;
    border: 0;
    padding-left: 1rem;
  }
}
